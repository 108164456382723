.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 65% !important;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3;
}

.files {
    margin-top: 40px;
    position: relative;
    border-radius: 5px;
    border: 1px dashed #000000 !important;
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 40px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 80%;
    right: 0;
    height: 85px;
    content: "Arrastre aquí ";
    display: block;
    margin: 0 auto;
    color: #034385;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}


input[type="file"]#uploader {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
    cursor: pointer;
}

.drop-zone-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.drop-zone {
    width: 90%;
    height: 200px;
    border: dashed;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    color: gray;
}

.drop-zone-text svg{
    margin-right: 10px;
}

.drop-zone-text {
    display: flex;
    position: absolute;
    top: 50%;
    font-size: initial;
    font-weight: bold;
    padding: 0 10px 0 10px;
    align-items: center;
}

.drop-zone-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}


@media screen and (max-width: 720px) {
    .files:before {
        content: "Selecciona uno o varios archivos";



    }
}