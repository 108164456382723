.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    flex-wrap: wrap;
}

.container-real-state{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.columna span {
    font-size: 30px;

}

.input_solicitud {
    width: 100%;
    height: 40px;
    border: 0.5px solid;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 0.7em;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    color:black;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    width: 350px;
    height: 540px;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
    color: var(--main-bg-color);
    text-decoration: none;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    font-size: 20px;
    padding: 0 0 20px 0;
}

.card:hover {
    background-color: var(--main-bg-color);
}

.container-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #005798;
    border-radius: 10px 10px 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-img span {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
}

hr {
    margin-bottom: 40px;
    border-radius: 10px;
    border-top: 2px solid #005798;
}

.container-text {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: center;
}

.container-text span {
    width: 100%;
    margin-bottom: 90px;
}

.container-text a {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container_modal_image {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.container_modal_title {
    text-align: center;
    margin-bottom: 20px;
}

.container_modal_btn {
    justify-content: center;
    display: flex;
}

.center-title {
    text-align: center;
}

.card input {
    margin-bottom: 20px;
}

.input_currentState {
    width: 100%;
    height: 40px;
    border: 0.5px solid;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 0.7em;
    padding: 0px 20px;
}

/* .btn_doc {
    width: 70%;
    border-radius: 3px;
    background: #ffffff;
    color: rgb(45, 43, 43);
    border: 1px solid #07438533;
    font-size: 0.7em;
    cursor: pointer;
    height: 40px;
    outline: none;
    font-weight: bold;
}

.btn_doc:hover {
    color: #fff;
    background-color: #004172;
    border-color: #003a65;
    transition: 0.5s;
    transform: scale(1.02);
} */

.btn_doc {
    padding: 1.6em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: bold;
    color: #000;
    background-color: #00569848;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.btn_doc:hover {
    background-color: #005798;
    box-shadow: 0px 15px 20px rgba(52, 116, 255, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.btn_doc:active {
    transform: translateY(-1px);
}

/* .doc_text {
    font-size: 20px;
    margin-bottom: 145px;
}

.doc_text span {
    margin-bottom: 40px;
} */

.info_gestor {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
}

.info_gestor a {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.admin_avatar {
    width: 80px;
    height: 80px;
    vertical-align: middle;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
}

.info_item {
    width: 100%;
}

.info_item_number {
    display: flex;
    justify-content: center;
}

.info_item_title {
    font-weight: bold;
}

.number_item {
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
}

.wa_button {
    display: flex;
    align-items: center;
    padding: 0.2em 1.4em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: bold;
    color: #000;
    background-color: #00569848;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.wa_button:hover {
    background-color: #005798;
    box-shadow: 0px 15px 20px rgba(52, 116, 255, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.wa_button img {
    margin-left: 8px;
    width: 30px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.badge div {
    width: 370px;
    font-size: 18px;
}

.banner {
    display: flex;
    width: auto;
    height: 90px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 15px;
    margin-bottom: 15px;
    flex-wrap: nowrap;

}

.column{
  width: 78%;
}

input{
    color: black;
    text-align: center;
}

@media(max-width: 1024px) {
    .card {
        width: 45%
    }

    .badge div {
        width: 100%;
        margin: 10px ;
    }

    /*  .input_solicitud {
        font-size: 10px;
    }

    .columna span {
        font-size: 20px;
    } */

    /* .container {
        display: block;
    } */

    h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 724px) {
    /* span {
        font-size: 15px;
    } */

    .card {
        width: 80%;
        height: auto;
    }

    h2 {
        font-size: 1.7rem;
    }
}