::placeholder { 
    color: #b7b7b7;
    font-size:17px;
    padding:9px;
}

.login-form-header_admin {
    padding-bottom: 20px;
    border-bottom: 1px solid #00000022;
    margin-bottom: 35px;
}

.login-form_admin{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 40px;
}
.form-group_admin{
    margin-bottom: 15px;
    display: block;
}
.login-form-header_admin{
    text-align: center;
    margin-bottom: 30px;
}
.form-group_admin label{
    font-weight: bold;
    font-size: 0.9em;
}
.input_login_admin{
    border: 0.5px solid;
    border-radius: 3px;
    margin: 10px;
    background-color: #07438522;
    border: 0;
    width: max-content;
    font-style:normal;
    padding:10px;
}
 
.form-label_admin{
    font-size: 13px;
    font-weight: 450;
    color: #555;
    line-height: 22px;
    font-family: "Source Sans Pro", sans-serif;
}

.login_button_admin{
    width: 100%;
    border-radius: 3px;
    background: #ffffff;
    color: rgb(45, 43, 43);
    border:1px solid #07438533;
    font-size: 0.7em;
    cursor: pointer;
    height: 40px;
    outline: none;
    font-weight: bold;
   
}
.forgot_button_admin{
    width: 100%;
    height:30px;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background: #6c757d;
    color: #fff;
    font-weight: bold;
    font-size: 0.7em;
    cursor: pointer;
}
.login_button_admin:hover{
    color: #fff;
    background-color : #004172;
    border-color: #003a65;
    transition: 0.5s;
    transform: scale(1.02);
}
.forgot_button_admin:hover{
    color: #fff;
    background-color : #5a6268;
    border-color: #5a6268;
    transition: 0.5s;
    transform: scale(1.02);
}
 
