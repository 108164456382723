::placeholder {
    color: #b7b7b7;
    font-size: 17px;
    padding: 9px;
}

.login-form-header_user {
    padding-bottom: 20px;
    border-bottom: 1px solid #00000022;
    margin-bottom: 35px;
}

.login-form_user {
    display: flex;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 40px;
}

.form-group_user {
    margin-bottom: 15px;
    display: block;
}

.form-group_user label {
    font-weight: bold;
    font-size: 1em;
}

.input_login_user {
    width: -webkit-fill-available;
    padding: 7px;
    height: 10px;
    border: 0.5px solid;
    border-radius: 3px;
    margin-top: 10px;
    font-size: .9em;
    font-weight: bold;
    width: 90%;
    background-color: #07438522;
    border: 0;
    font-style: normal;
    padding: 20px;
}

.form-label_user {
    font-size: 13px;
    font-weight: 450;
    color: #555;
    line-height: 22px;
    font-family: "Source Sans Pro", sans-serif;
}

.login_button_user {
    width: 100%;
    border-radius: 3px;
    background: #ffffff;
    color: rgb(45, 43, 43);
    border: 1px solid #07438533;
    font-size: 0.7em;
    cursor: pointer;
    height: 40px;
    outline: none;
    font-weight: bold;
}

.forgot_button_user {
    width: 100%;
    height: 30px;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background: #6c757d;
    color: #fff;
    font-weight: bold;
    font-size: 0.7em;
    cursor: pointer;
}

.login_button_user:hover {
    color: #fff;
    background-color: #004172;
    border-color: #003a65;
    transition: 0.5s;
    transform: scale(1.02);
}

.forgot_button_user:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #5a6268;
    transition: 0.5s;
    transform: scale(1.02);
}