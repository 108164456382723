.navbar{
    background-color: #005798;
    box-shadow: 0 1px 0 rgba(0,0,0,.05);
    display: flex;
    justify-content: center;
    vertical-align: center;
    height:74px;
    align-items: center;
    box-shadow:1px 1px 3px black;
}

.logo-real-state{
    width: 100%;
    height: fit-content;
    /* position: absolute;
    width: 75px;
    top: 0px;
    height: fit-content;
    background-color: white;
    right: 0px; */
}

.logo-conainer{
    position: absolute;
    width: 75px;
    top: 0px;
    height: fit-content;
    background-color: white;
    right: 0px; 
    display: flex;
    justify-content: flex-end
}