.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.icon_hover {
    color: #00bcd4;
}

.container-files {
    max-width: 800px;
    margin: auto;
    margin-top: 10px;
}


.button-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    margin-left: 20px;
    height: 43px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #034385;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.returnButton-container {
    display: flex;
    justify-content: space-around;

}

.button-back {
    margin: 5px;
    padding: 2px 24px;
    border-radius: 20px;
    background-color: #005798;
    border-color: black
}

.title {
    padding: 10px 25px;
    color: white;
    background-color: #ccc;
    margin-top: 0 !important;
}

.title p:first-child {
    color: black;
    font-style: oblique;
}