:root {
  --main-bg-color:·#034385;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position:relative;
  min-height: 100vh;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin:0px;
  padding:0px;
}
 
.cont{
  padding-top:70px;
}

.dflex{
  display:flex;
}
.justcenter{
  justify-content: center;
}
.justaround{
  justify-content: space-around;
}
.justbetween{
  justify-content: space-between;
}

.align-center{
  align-items: center;
}