.container-selectedDocument {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding: 15px
}


.card-selectedDocument {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 3px 1px black;
    cursor: pointer;
    width: 40%;
    height: 80%;
    padding: 20px;
    color: var(--main-bg-color);
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    font-size: 15px;

}

.card-selectedDocument:hover {
    background-color: var(--main-bg-color);
}

.selected-document__title {
    display: block;

}

.line-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 5px;
    margin-left: 5%;
    margin-right: 5%;
}

.uploader-container {
    padding: 20px;
}

@media screen and (max-width: 720px) {
    .card-selectedDocument {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .card-selectedDocument {
        width: 100%;
        font-size: 10px;
        padding: 0;
    }
}

.selectedDocumentContainer {
    display: flex;
    justify-content: center;
}