.row{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    height:50px;
    box-sizing: border-box;
    color:#000000;
    font-size:14px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: normal;
    align-items: center;
   margin-bottom:15px;
    padding-right: 20px;;
}
.container_row{
    display: flex;
    justify-content: center;
}

.container_row .row-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 100;
    white-space: nowrap;
    padding: 5px;
    color: white;
  }

.small{
    width:60%;
}
.medium{
    width:55%;
}
.large{
    width:85%;
}

@media (max-width: 770px) {
    .row{
        height: 70px;
    }
}