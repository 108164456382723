.sendButton{
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 103px;
    text-decoration: none;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #E4E7ED;
    color:white;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all .3s;
    margin-left:4px;
    box-sizing: border-box;
    font-size:14px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.sendButton:hover{
    box-shadow: 3px 3px rgba(133, 133, 133, 0.2);
    background-color: #fefefe;
}
.disabled{
    opacity:0.2;
}

 
@media (max-width: 770px) {
    .sendButton{
        height: 70px;
    }
}